import React from "react";
import { Box } from "@mui/material";
import "./App.css";
import Body from "../main/Body";
import { ConfigProvider } from "../Context";

const config = require("../config/development.json");

function App() {
  return (
    <ConfigProvider value={config}>
      <Box className="App">
        <Body />
      </Box>
    </ConfigProvider>
  );
}

export default App;
