import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import './Body.css'
import Dates from '../Dates/Dates'
import { SocialStack } from '../social/social-stack'
import { useTheme } from '@mui/material/styles'

function Body() {
  const theme = useTheme()
  return (
    <Grid container width="100vw">
      <Box className="main" sx={{ width: '100%' }}>
        <Grid
          item
          xs={30}
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Box
            sx={{
              mb: 3,
            }}
          >
            <Grid
              container
              justifyContent="end"
              sx={{
                [theme.breakpoints.down('sm')]: { justifyContent: 'start' },
              }}
            >
              <Grid item xs={6} mt={1} md={4.7}>
                <SocialStack />
              </Grid>
            </Grid>
            <Box sx={{ textAlign: 'left' }}>
              <Typography
                color={`${theme.palette.text.primary}`}
                fontSize="35px"
                letterSpacing={15}
                fontFamily="inter"
              >
                ELLIOT ROBERTSON
              </Typography>
            </Box>
            <Grid container width="100%">
              <Grid
                item
                md={12}
                xs={6}
                textAlign="right"
                sx={{ [theme.breakpoints.down('sm')]: { textAlign: 'left' } }}
              >
                <Typography
                  variant="p"
                  letterSpacing={15}
                  fontFamily="inter"
                  fontSize="18px"
                >
                  GUITARIST
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
          overflow="scroll"
          item
          width="97%"
          xs={20}
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Box marginBottom="10px" width="100%" height="100%">
            <Dates />
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}

export default Body
