import React from 'react'
import { Stack, Grid } from '@mui/material'
import ig from '../assets/social-icons/instagram.png'
import tikTok from '../assets/social-icons/tik-tok.png'
import facebook from '../assets/social-icons/facebook.png'
import spotify from '../assets/social-icons/spotify.png'
import youtube from '../assets/social-icons/youtube.png'

export const SocialStack = () => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}
    >
      <Grid
        item
        xs={1.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <a href="https://www.instagram.com/ellriffs/?hl=en-gb">
          <img height="15px" src={ig} alt="ig-logo" />
        </a>
      </Grid>
      <Grid
        item
        xs={1.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <a style={{ display: 'flex' }} href="https://www.tiktok.com/@ellriffs">
          <img height="15px" src={tikTok} alt="tt-logo" />
        </a>
      </Grid>
      <Grid
        item
        xs={1.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <a href="https://www.facebook.com/EllRiffs/">
          <img height="15px" src={facebook} alt="fb-logo" />
        </a>
      </Grid>
      <Grid
        item
        xs={1.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <a href="https://open.spotify.com/artist/2nzxvNYXS7Y4ClULz1j8ha?si=eRTWt4IWSJ-Qhl7JmaOf-w">
          <img height="15px" src={spotify} alt="spotify-logo" />
        </a>
      </Grid>
      <Grid
        item
        xs={1.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <a href="https://www.youtube.com/channel/UCipr6tBdl6irSIOwKNaV9Cg">
          <img height="15px" src={youtube} alt="yt-logo" />
        </a>
      </Grid>
    </Stack>
  )
}
