import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import './index.css'
import App from './app/App'
import store from './redux/store'
import { darkTheme } from './theme'

const root = ReactDOM.createRoot(document.getElementById('body'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline>
          <App />
        </CssBaseline>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)
