/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getAllDates from "../../service/getAllDates";

const { tourDatesUrl } = require("../../config/development.json");

const initialState = {
  dates: [],
  error: "",
  sortingKey: "date",
};

export const fetchDates = createAsyncThunk("dates/fetchAllDates", (_) => {
  const response = getAllDates(tourDatesUrl);
  return response;
});

const datesReducer = createSlice({
  name: "dates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDates.fulfilled, (state, action) => {
        state.staus = "succeeded";
        state.dates = action.payload.sort((a, b) =>
          a[state.sortingKey].slice(5, 7) < b[state.sortingKey].slice(5, 7)
            ? -1
            : 1,
        );
      })
      .addCase(fetchDates.rejected, (state, action) => {
        state.staus = "failed";
        state.error = action.payload.error;
      });
  },
});

export default datesReducer.reducer;
