import { createTheme } from '@mui/material'

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: ({ theme }) => ({
          backgroundColor: '#C55326',
        }),
      },
    },
  },
  typography: {
    fontFamily: ['Oswald', 'Homemade Apple'],
  },
})
