/* eslint-disable consistent-return */
/* eslint-disable no-console */
const getAllDates = async (url) => {
  try {
    const res = await fetch(url)
    return res.json()
  } catch (err) {
    console.warn('unable to fetch data from server, please try again later')
  }
}

export default getAllDates
