/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchDates } from "../redux/reducers/fetchAllDates";
import { useTheme } from "@mui/material/styles";

function Dates() {
  const dispatch = useDispatch();
  const dates = useSelector((state) => state.dates);

  useEffect(() => {
    dispatch(fetchDates());
  }, [dispatch]);

  const theme = useTheme()

  return (
    <div
      overflow="scroll"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography marginTop={10} fontFamily="inter" letterSpacing={5}>
        UPCOMING DATES 2023
      </Typography>
      <Grid container md={7} sm={12} xs={12}>
        {dates?.map((date) => (
          <Grid
            key={date._id}
            item
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: 2,
              marginBottom: "10px",
              marginTop: "10px",
              height: "50px",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
            className="date-row"
          >
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Typography fontSize="14px" fontFamily="inter" letterSpacing={1}>
                {moment.utc(date.date).format("D MMM")}
              </Typography>
            </Grid>
            <Grid item xs={16}>
              <Typography fontSize="14px" fontFamily="inter"  letterSpacing={2}>
                {date.venue}
              </Typography>
              <Typography fontSize="14px" fontFamily="inter" color={theme.palette.text.secondary} letterSpacing={2}>
                {date.city}
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                sx={{ color: "white" }}
                href={date.ticketUrl}
                target="_blank"
                variant="contained"
                size="small"
              >
                <Typography
                  fontSize="11px"
                  fontFamily="inter"
                  letterSpacing={1}
                >
                  Get Tickets
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Dates;
